import { Config } from '../../types/config';
import { Background, SearchLabel, SummaryTrigger } from './summary.styles';
import { useApplication } from '../../hooks/useApplication';
import { useBlanket } from '../../hooks/useBlanket';
import useMediaQuery, { BreakPointToken } from '../../hooks/useMediaQuery';
import { useTheme } from '@emotion/react';

import { ContainerComponent } from '@vaa-component-lib/component.layout.container';
import SummaryTickerComponent from '../summary-ticker/summary-ticker.component';
import SummaryPlaybackComponent from '../summary-playback/summary-playback.component';
import {
	IconActionSearchComponent,
	IconsSize
} from '@vaa-component-lib/component.atom.icons';
import CriteriaService from '../../lib/utils/criteria.service';
import { TypographyComponent } from '@vaa-component-lib/component.atom.typography';

interface SummaryComponentProps {
	config: Config;
}

const SummaryComponent = ({ config }: SummaryComponentProps) => {
	const { application, setApplication } = useApplication();
	const { blanket, setBlanket } = useBlanket();
	const theme = useTheme();

	const openExperience = () => {
		setBlanket({ ...blanket, show: true });
		setApplication({ ...application, open: true });
	};

	const { summary: summaryConfig } = config;
	const hasSummary = CriteriaService.validateCriteriaCanShowPlayback(
		application?.criteria
	);

	const isMidSize = useMediaQuery(`(min-width: ${BreakPointToken.MdMin}px)`);
	const isDesktop = useMediaQuery(`(min-width: ${BreakPointToken.LgMin}px)`);

	return (
		<Background
			hasSummary={hasSummary}
			float={application?.float}
			theme={theme}
			data-cy="summary-component"
			data-float={application?.float}
			role="region">
			<ContainerComponent as="section">
				<SummaryTrigger
					data-cy="summary-trigger"
					onClick={() => openExperience()}
					visible={!application?.open}
					tabIndex={application?.open ? -1 : 0}>
					{hasSummary ? (
						<SummaryPlaybackComponent />
					) : (
						<SummaryTickerComponent {...summaryConfig} />
					)}

					<SearchLabel data-cy="summary-search-label">
						<IconActionSearchComponent
							size={isMidSize ? IconsSize.Med : IconsSize.Sml}
						/>
						<TypographyComponent>
							{hasSummary ? summaryConfig.editButton : summaryConfig.button}
						</TypographyComponent>
					</SearchLabel>
				</SummaryTrigger>
			</ContainerComponent>
		</Background>
	);
};

export default SummaryComponent;
