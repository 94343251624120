/** @jsx jsx */
import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import tokens from '@vaa-component-lib/shared.design-tokens/json/variables.json';
import { transparentize } from 'polished';

import { BreakPointToken } from '../../hooks/useMediaQuery';

type BackgroundProps = {
	hasSummary?: boolean;
	float?: boolean;
	theme: Theme;
};

type ButtonProps = {
	visible: boolean;
};

const {
	spacing: {
		size_8px: { height: size_8 },
		size_12px: { height: size_12 },
		size_16px: { height: size_16 },
		size_20px: { height: size_20 },
		size_24px: { height: size_24 },
		size_40px: { height: size_40 },
		size_48px: { height: size_48 }
	},
	color: colours
} = tokens;

// This seems daft but you have to pass the theme in to use theme _and_ props. Sure there's another way around this though...
export const Background = styled.div<BackgroundProps>`
	${(props: BackgroundProps) => ({
		backdropFilter: props?.float ? `blur(10px)` : 'none',
		backgroundColor: props?.hasSummary
			? 'var(--token-color-colour-background-secondary)'
			: 'var(--token-color-colour-background-red)',
		padding: `${size_16}px 0`,
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,

		[`@media screen and (min-width: ${BreakPointToken.SmMin}px)`]: {
			padding: `${size_20}px 0`
		}
	})}
`;

export const SummaryTrigger = styled.button<ButtonProps>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	background: ${colours.light.neutral_0};
	border: none;
	cursor: pointer;
	padding: ${size_12}px;
	border-radius: ${size_40}px;
	opacity: 1;
	color: ${colours.light.text_primary};

	@media (min-width: ${BreakPointToken.SmMin}px) {
		padding: ${size_16}px;
		border: 1px solid ${colours.light.border_decorative};
		opacity: ${({ visible }) => (visible ? 1 : 0)};
		transition: opacity 0.3s ease-in-out;
	}
`;

export const SearchIcon = styled.div`
	width: ${size_40}px;
	height: ${size_40}px;
	border-radius: ${size_40}px;
	background: ${colours.light.brandprimary_60};
	color: ${colours.light.neutral_0};
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;

	@media (min-width: ${BreakPointToken.SmMin}px) {
		width: ${size_48}px;
		height: ${size_48}px;
	}
`;

export const SearchLabel = styled.div`
	display: block;
	flex-shrink: 0;
	margin: 0 0 0 ${size_24}px;
	pointer-events: none;
	background: var(--token-color-colour-background-red);
	color: var(--token-color-colour-static-white);
	padding: 12px 16px;
	border-radius: ${size_24}px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		margin-inline-end: ${size_8}px;
	}
`;
